// src/components/AdminPanel.tsx

import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

// Define the interface for a user item
interface UserItem {
  id: number;
  full_name: string;
  email: string;
  phone: string;
  assigned_to_name?: string;
}

const AdminPanel: React.FC = () => {
  const { user, logout } = useAuth();
  const [users, setUsers] = useState<UserItem[]>([]);
  const navigate = useNavigate();

  // Fetch the list of users when the component mounts
  useEffect(() => {
    if (user && user.is_admin) {
      fetchUsers();
    } else {
      // If the user is not an admin, redirect to the dashboard or login page
      navigate(user ? '/dashboard' : '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Function to fetch the list of users
  const fetchUsers = async () => {
    try {
      const response = await fetch('/api/admin/get_users.php', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      if (data.success) {
        setUsers(data.users);
      } else {
        alert('Erro ao carregar usuários: ' + data.message);
      }
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      alert('Erro ao conectar com o servidor');
    }
  };

  // Function to generate the draw
  const handleGenerateDraw = async () => {
    if (!window.confirm('Tem certeza de que deseja gerar o sorteio?')) return;

    try {
      const response = await fetch('/api/admin/generate_draw.php', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      if (data.success) {
        alert('Sorteio realizado com sucesso!');
        fetchUsers(); // Update the user list
      } else {
        alert('Erro ao gerar sorteio: ' + data.message);
      }
    } catch (error) {
      console.error('Erro ao gerar sorteio:', error);
      alert('Erro ao conectar com o servidor');
    }
  };

  // Function to send emails
  const handleSendEmails = async () => {
    if (!window.confirm('Tem certeza de que deseja enviar os emails?')) return;

    try {
      const response = await fetch('/api/admin/send_emails.php', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      if (data.success) {
        alert('Emails enviados com sucesso!');
      } else {
        alert('Erro ao enviar emails: ' + data.message);
      }
    } catch (error) {
      console.error('Erro ao enviar emails:', error);
      alert('Erro ao conectar com o servidor');
    }
  };

  // Function to delete a user
  const handleDeleteUser = async (userId: number) => {
    if (!window.confirm('Tem certeza de que deseja deletar este usuário?')) return;

    try {
      const response = await fetch('/api/admin/delete_user.php', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ user_id: userId.toString() }),
      });

      const data = await response.json();
      if (data.success) {
        alert('Usuário deletado com sucesso');
        fetchUsers(); // Update the user list
      } else {
        alert('Erro ao deletar usuário: ' + data.message);
      }
    } catch (error) {
      console.error('Erro ao deletar usuário:', error);
      alert('Erro ao conectar com o servidor');
    }
  };

  // Function to handle logout and navigate to the login page
  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Painel do Administrador
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogout}
        style={{ float: 'right' }}
      >
        Logout
      </Button>
      <div style={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateDraw}
          style={{ marginRight: '10px' }}
        >
          Gerar Sorteio
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSendEmails}>
          Enviar Emails
        </Button>
      </div>
      <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
        Usuários Inscritos
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Nome Completo</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Celular</TableCell>
            <TableCell>Amigo Secreto</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((userItem) => (
            <TableRow key={userItem.id}>
              <TableCell>{userItem.id}</TableCell>
              <TableCell>{userItem.full_name}</TableCell>
              <TableCell>{userItem.email}</TableCell>
              <TableCell>{userItem.phone}</TableCell>
              <TableCell>{userItem.assigned_to_name || 'Não atribuído'}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDeleteUser(userItem.id)}
                >
                  Deletar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default AdminPanel;
