// src/components/SignUpForm.tsx

import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SignUpForm: React.FC = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await fetch('/api/signup.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fullName, email, phone, password }),
      });

      const data = await response.json();
      if (data.success) {
        alert('Inscrição realizada com sucesso!');
        navigate('/'); // Redirect to the login page
      } else {
        alert('Erro: ' + data.message);
      }
    } catch (error) {
      console.error('Erro ao inscrever:', error);
      alert('Erro ao conectar com o servidor');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Inscreva-se
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nome Completo"
          variant="outlined"
          fullWidth
          margin="normal"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Número de Celular"
          variant="outlined"
          fullWidth
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <TextField
          label="Senha"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Inscrever-se
        </Button>
      </form>
      <Typography variant="body1" align="center" style={{ marginTop: '20px' }}>
        Já tem uma conta?{' '}
        <Link href="/" onClick={() => navigate('/')}>
          Faça login
        </Link>
      </Typography>
    </Container>
  );
};

export default SignUpForm;
