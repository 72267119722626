// src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import { AuthProvider } from './AuthContext';
import LoginForm from './components/LoginForm';
import Dashboard from './components/Dashboard';
import AdminPanel from './components/AdminPanel';
import SignUpForm from './components/SignUpForm';
import { useAuth } from './AuthContext';

const AppRoutes: React.FC = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginForm />}
      />
      <Route
        path="/signup"
        element={!isAuthenticated ? <SignUpForm /> : <Navigate to="/dashboard" />}
      />
      <Route
        path="/dashboard"
        element={
          isAuthenticated && !user?.is_admin ? (
            <Dashboard />
          ) : isAuthenticated && user?.is_admin ? (
            <Navigate to="/admin" />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/admin"
        element={
          isAuthenticated && user?.is_admin ? <AdminPanel /> : <Navigate to="/" />
        }
      />
      {/* Catch-all route for undefined paths */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
