// src/components/Dashboard.tsx
import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Card, CardContent } from '@mui/material';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

interface UserDetailsResponse {
  success: boolean;
  assignedUser?: string;
  eventInfo?: string;
  message?: string;
}

const Dashboard: React.FC = () => {
  const { user, logout } = useAuth();
  const [assignedUser, setAssignedUser] = useState<string | null>(null);
  const [eventInfo, setEventInfo] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('/api/get_user_details.php', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const data: UserDetailsResponse = await response.json();
        if (data.success) {
          setAssignedUser(data.assignedUser || null);
          setEventInfo(data.eventInfo || null);
        } else {
          alert('Erro ao carregar os dados do usuário: ' + data.message);
        }
      } catch (error) {
        console.error('Erro ao carregar os dados do usuário:', error);
        alert('Erro ao conectar com o servidor');
      }
    };

    fetchUserDetails();
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Bem-vindo, {user?.full_name}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogout}
        style={{ float: 'right' }}
      >
        Logout
      </Button>
      <Card>
        <CardContent>
          <Typography variant="h6">Quem você tirou:</Typography>
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            {assignedUser ? assignedUser : 'O sorteio ainda não foi realizado.'}
          </Typography>
          <Typography variant="h6" style={{ marginTop: '20px' }}>
            Informações do evento:
          </Typography>
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            {eventInfo ? eventInfo : 'As informações do evento serão adicionadas em breve.'}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Dashboard;
